import { LEASE_TERMS } from "../../components/constants";
import { postingConstants } from "../_constants";

const initialState = {
  postingDetails: {
    postingFor: "",
    preferredGender: "any",
    numberOfTenantsRequired: 1,
    apartmentSharedWith: null,
    roomSharedWith: null,
    bathSharedWith: null,
    isMasterBed: false,
    apartmentType: "apartment",
    location: {
      coordinates: [0, 0],
      address: {
        houseNumber: "",
        locality: "",
        city: "",
        state: ""
      }
    },
    price: {
      rent: null,
      securityDeposit: null,
      maintenance: null,
      isNegotiable: true
    },
    contact: {
      person: "",
      phoneNumber: [],
      email: []
    },
    furnishing: "semi-furnished",
    amenities: ["fan"],
    images: [],
    isDrafted: false,
    heading: "",
    description: "",
    availableFrom: "",
    leaseTerm: LEASE_TERMS[0]
  },
  images: [],
  uploadImagesRequest: false,
  uploadImagesSuccess: false,
  uploadImagesFailure: false,
  postingRequest: false,
  postingSuccess: false,
  postingFailure: false,
  deletePostingRequest: false,
  deletePostingSuccess: false,
  deletePostingFailure: false,
  deletedPostingId: null,
  paymentFailure: false,
  plan: null,
  deletePostingToggle: false,
  isModeEdit: false
};

export function posting(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case postingConstants.SET_POSTING_DETAILS:
      return {
        ...state,
        postingDetails: {
          ...state.postingDetails,
          [action.detailName]: action.detailValue
        }
      };

    case postingConstants.SET_PROPERTY_ADDRESS:
      return {
        ...state,
        postingDetails: {
          ...state.postingDetails,
          location: {
            ...state.postingDetails.location,
            address: action.address
          }
        }
      };

    case postingConstants.SET_PROPERTY_ADDRESS_CORDINATES:
      return {
        ...state,
        postingDetails: {
          ...state.postingDetails,
          location: {
            ...state.postingDetails.location,
            coordinates: action.latlang
          }
        }
      };

    case postingConstants.SET_PROPERTY_PRICE:
      return {
        ...state,
        postingDetails: {
          ...state.postingDetails,
          price: action.price
        }
      };

    case postingConstants.SET_IMAGES:
      return {
        ...state,
        images: action.detailValue
      };

    case postingConstants.UPLOAD_IMAGES_REQUEST:
      return {
        ...state,
        uploadImagesRequest: true
      };

    case postingConstants.UPLOAD_IMAGES_SUCCESS:
      return {
        ...state
        //listings: action.listings,
      };

    case postingConstants.UPLOAD_IMAGES_FAILURE:
      return {
        ...state,
        uploadImagesFailure: true,
        uploadImagesRequest: false
      };
    case postingConstants.DELETE_POSTING_REQUEST:
      return {
        ...state,
        deletePostingRequest: true
      };

    case postingConstants.DELETE_POSTING_SUCCESS:
      return {
        ...state,
        deletedPostingId: action.deletedPostingId,
        deletePostingToggle: !state.deletePostingToggle,
        deletePostingSuccess: true,
        deletePostingRequest: false
      };

    case postingConstants.DELETE_POSTING_FAILURE:
      return {
        ...state,
        deletePostingFailure: true,
        deletePostingRequest: false
      };

    case postingConstants.POSTING_REQUEST:
      return {
        ...state,
        postingRequest: true
      };
    case postingConstants.POSTING_SUCCESS:
      return {
        ...state,
        plan: action.plan,
        postingSuccess: true,
        postingRequest: false,
        uploadImagesSuccess: true,
        uploadImagesRequest: false
      };
    case postingConstants.POSTING_FAILURE:
      return {
        ...state,
        postingFailure: true,
        postingRequest: false
      };
    case postingConstants.PAYMENT_FAILURE:
      return {
        ...state,
        paymentFailure: true
      };
    case postingConstants.PAYMENT_REQUEST:
      return {
        ...state,
        plan: action.plan
      };
    case postingConstants.CONTACT_FORM_FAILURE:
      return {
        ...state,
        contactFormFailure: true,
        contactFormRequest: false
      };
    case postingConstants.CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFormSuccess: true,
        contactFormRequest: false
      };
    case postingConstants.CONTACT_FORM_REQUEST:
      return {
        ...state,
        contactFormRequest: true
      };
    case postingConstants.SET_EDIT_POSTING_DETAILS:
      return {
        ...state,
        postingDetails: action.postingDetails,
        isModeEdit: true
      };

    case postingConstants.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        postingDetails: {
          ...state.postingDetails,
          images: state.postingDetails.images.filter(
            image => image.url !== action.url
          )
        }
      };
    case postingConstants.SET_PLAN:
      return {
        ...state,
        plan: action.plan,
        postingDetails: initialState.postingDetails,
        images: [],
        isModeEdit: false,
        uploadImagesRequest: false,
        uploadImagesSuccess: false,
        uploadImagesFailure: false,
        postingRequest: false,
        postingSuccess: false,
        postingFailure: false
      };
    case postingConstants.RESET_POSTING_DETAILS:
      return initialState

    default:
      return state;
  }
}

import { requirementsConstants } from "../_constants";

const initialState = {
  requirementsDetails: {
    requirementType: ["sharing-room"],
    locality: null,
    radius: 5,
    rent: null,
    description: "",
    city: null,
    name: "",
    // phoneNumber: ""
  },
  postRequirementRequest: false,
  postRequirementSuccess: false,
  postRequirementFailure: false,
  successResponse: null
};

export function requirements(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case requirementsConstants.SET_REQUIREMENTS_DETAILS:
      return {
        ...state,
        requirementsDetails: {
          ...state.requirementsDetails,
          [action.detailName]: action.detailValue
        }
      };

    case requirementsConstants.POST_REQUIREMENTS_REQUEST:
      return {
        ...state,
        postRequirementRequest: true,
        postRequirementSuccess: false,
        postRequirementFailure: false
      };

    case requirementsConstants.POST_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        listings: action.listings,
        postRequirementSuccess: true,
        postRequirementRequest: false,
        successResponse: action.response
      };

    case requirementsConstants.POST_REQUIREMENTS_FAILURE:
      return {
        ...state,
        postRequirementFailure: true,
        postRequirementRequest: false,
        error: action.error
      };

    default:
      return state;
  }
}

import { SignalCellularNullOutlined } from "@material-ui/icons";
import { listingsConstants } from "../_constants";

const initialState = {
  listings: [],
  myListings: [],
  getListingsRequest: false,
  getListingsSuccess: false,
  getListingsFailure: false,
  getMyListingsRequest: false,
  getMyListingsSuccess: false,
  getMyListingsFailure: false,
  currentListingIndex: null,
  isSingleListing: false,
  p: 1,
  c: 10,
  sw: null,
  ne: null,
  lat: null,
  lng: null,
  zooom: null,
  gender: null,
  roomType: null,
  apartmentType: null,
  amenities: null,
  furnish: null,
  minPrice: null,
  maxPrice: null,
  setPlaceValuesSuccess: false,
  getListingsToggle: false,
  getMyListingsToggle: false,
  setPlaceValuesToggle: false,
  pageCount: false,
  centerLat: null,
  centerLng: null,
  contactNumber: null,
  contactEmail: null,
  getContactNumberRequest: false,
  getContactNumberSuccess: false,
  getContactNumberFailure: false,
  contactPerson: null
};

export function listings(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case listingsConstants.GET_LISTINGS_REQUEST:
      return {
        ...state,
        getListingsRequest: true
      };

    case listingsConstants.GET_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: action.listings,
        getListingsSuccess: true,
        getListingsRequest: false,
        getListingsToggle: !state.getListingsToggle
      };

    case listingsConstants.GET_LISTINGS_FAILURE:
      return {
        ...state,
        getMyListingsFailure: true,
        getMyListingsRequest: false
      };

    case listingsConstants.GET_MY_LISTINGS_REQUEST:
      return {
        ...state,
        getMyListingsRequest: true
      };

    case listingsConstants.GET_MY_LISTINGS_SUCCESS:
      return {
        ...state,
        myListings: action.myListings,
        getMyListingsSuccess: true,
        getMyListingsRequest: false,
        getMyListingsToggle: !state.MygetListingsToggle
      };

    case listingsConstants.GET_MY_LISTINGS_FAILURE:
      return {
        ...state,
        getListingsFailure: true,
        getListingsRequest: false
      };

    case listingsConstants.SET_LISTING_INDEX:
      return {
        ...state,
        currentListingIndex: action.listingIndex,
        isSingleListing: true
      };

    case listingsConstants.SET_BOUNDS:
      return {
        ...state,
        sw: action.sw,
        ne: action.ne,
        centerLat: action.lat,
        centerLng: action.lng
      };

    case listingsConstants.REFRESH_PAGE_COUNT:
      return {
        ...state,
        pageCount: !state.pageCount
      };

    case listingsConstants.SET_PLACE_VALUES:
      return {
        ...state,
        lat: action.lat,
        lng: action.lng,
        zoom: action.zoom,
        setPlaceValuesToggle: !state.setPlaceValuesToggle
      };

    case listingsConstants.SET_FILTERS:
      return {
        ...state,
        gender: action.gender,
        roomType: action.roomType,
        apartmentType: action.apartmentType,
        amenities: action.amenities,
        furnish: action.furnish,
        minPrice: action.minPrice,
        maxPrice: action.maxPrice
      };
    case listingsConstants.GET_CONTACT_NUMBER_REQUEST:
      return {
        ...state,
        getContactNumberRequest: true
      };
    case listingsConstants.GET_CONTACT_NUMBER_SUCCESS:
      return {
        ...state,
        contactNumber: action.contactNumber,
        contactPerson: action.contactPerson,
        contactEmail: action.contactEmail,
        getContactNumberRequest: false,
        getContactNumberSuccess: true
      };
    case listingsConstants.GET_CONTACT_NUMBER_FAILURE:
      return {
        ...state,
        getContactNumberRequest: false,
        getContactNumberFailure: true
      };

    default:
      return state;
  }
}

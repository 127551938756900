import { combineReducers } from 'redux';

import { routing } from './routing.reducers.js';
import { auth } from './auth.reducers.js';
import { listings } from './listings.reducers.js';
import { posting } from './posting.reducers.js';
import { requirements } from './requirements.reducers.js';

const rootReducer = combineReducers({
  routing,
  auth,
  listings,
  requirements,
  posting
});

export default rootReducer;